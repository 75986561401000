import React, { useEffect, useState } from 'react';
import './Contact.scss';

const Contact: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
  
    fetch('submit_contact.php', {
      method: 'POST',
      body: formData,
    })
    .then(response => response.text())
    .then(data => {
      console.log(data); // Log the response from PHP
      // Handle success or display a message to the user
      alert("Message sent successfully");
      setFormData({
        name: '',
        email: '',
        message: '',
      });
    })
    .catch(error => {
      console.error('Error:', error);
      // Handle error case
    });
  };

  useEffect(() => {
    // Trigger animation when component mounts
    setIsVisible(true);
  }, []);
  

  return (
    <section className="contact">
      <h2>Contact Us</h2>
      <div className={`contact-container ${isVisible ? 'is-visible' : ''}`}>
        {/* Contact Form */}
        <div className="contact-form">
          <form action="submit_contact.php" onSubmit={handleSubmit} method="POST">
            <label htmlFor="name">Name:</label>
            <input type="text" name="name" placeholder="Your Name" value={formData.name}
        onChange={handleChange} required />

            <label htmlFor="email">Email:</label>
            <input type="email" name="email" placeholder="Your Email" value={formData.email}
        onChange={handleChange} required />

            <label htmlFor="message">Message:</label>
            <input name="message" placeholder="Your Message" value={formData.message}onChange={handleChange} required></input>
            
            <button type="submit" value='Submit'>Submit</button>
          </form>
        </div>

        {/* Contact Information */}
        <div className="contact-info">
          <h3>Our Main Branch</h3>
          <p><strong>Address:</strong> Yeyyadi, Mangaluru, Karnataka 575016</p>
          <p><strong>Email:</strong> contact@easylifeenterprise.in</p>
          <p><i className="fas fa-phone-alt"></i><strong>Phone:</strong> +91 9901876632</p>
          <p><strong>Working Hours:</strong> Mon-Fri, 9:00 AM - 6:00 PM</p>
        </div>
      </div>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.1930405062144!2d74.85572686886299!3d12.895305388755208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba35b2491e04633%3A0x84ee2200f57b8227!2sEasy%20Life%20Enterprises!5e0!3m2!1sen!2sin!4v1731092494853!5m2!1sen!2sin"
         width="100%"
         height="450" 
         style={{ border: "0" }}
         allowFullScreen
         loading="lazy" >
         </iframe>
    </section>
  );
};

export default Contact;
