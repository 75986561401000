import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Products.scss';
import Irregationimage from '../../assets/images/collections/irregation.jpg';
import VACCUMCLEANER from '../../assets/images/collections/Vaccum.jpg';
import DAIRYPRODUCTS from '../../assets/images/collections/dairy.jpg';
import GARDENEQUIPMENTS from '../../assets/images/collections/gradaning.jpg';
import HONEYEQUIPMENTS from '../../assets/images/collections/honney.jpg';
import SPRAYER from '../../assets/images/collections/spares.jpg';
import TRACTOREQUIPMENTS from '../../assets/images/collections/tractor equpment.jpg';
import WHEELBORROW from '../../assets/images/collections/wheel.jpg';
import ARECAEQUIPMENTS from '../../assets/images/collections/areca.jpg';
import WEEDCUTTERATTACHMENTS from '../../assets/images/collections/weed cutter.jpg';
import OTHEREQUIPMENTS from '../../assets/images/collections/other.jpg';
import CollectionCard from '../Home/collection/CollectionCard';
import ProductWeed from '../../assets/images/Products/easylife product Weed.jpg';
import ProductWeed1 from '../../assets/images/Products/easylife product weed cutter list.jpg';
import ProductAreca from '../../assets/images/Products/easylife product areca.jpg';
import ProductAreca1 from '../../assets/images/Products/easylife product areca list.jpg';
import Productmoto from '../../assets/images/Products/easylife product motocart1.jpg';
import Productmoto2 from '../../assets/images/Products/easylife product motocart 2.jpg';
import ProductSprayer from '../../assets/images/Products/easylife product sprayer main.jpg';
import ProductSorayer1 from '../../assets/images/Products/easylife product sprayer.jpg';
import ProductWheel from '../../assets/images/Products/easylife product wheel borrow.jpg';
import Productother1 from '../../assets/images/Products/easylife product climbing.jpg';
import Productother2 from '../../assets/images/Products/easylife product chainsaw.jpg';
import Productother3 from '../../assets/images/Products/easylife product power tools.jpg';
import ProductHoney from '../../assets/images/Products/easylife product Honey.jpg';
import ProductTractor from '../../assets/images/Products/easylife product Tractor.jpg';
import ProductDairy from '../../assets/images/Products/easylife product dairy.jpg';
import ProductGarden from '../../assets/images/Products/easylife product garden.jpg';
import ProductIrrigation from '../../assets/images/Products/easylife product irrigation.jpg';
import ProductVaccum from '../../assets/images/Products/easylife product vaccum.jpg';
import Productweed3 from '../../assets/images/Products/easylife product weed cutter 3.jpg';
import Productothers from '../../assets/images/Products/easylife product others.jpg';








const products = [
  { id: 1, name: 'Irrigation Tool', category: 'Irrigation', image: ProductIrrigation, description: 'High-quality irrigation tools for efficient water management.' },
  { id: 2, name: 'Vacuum Clearner', category: 'Vacuum-Clearner', image: ProductVaccum, description: 'Industrial-grade vacuum cleaners designed to keep farm storage areas free from dust, debris, and pests.' },
  { id: 3, name: 'Garden Equipmets', category: 'Garden-Equipmets', image: ProductGarden, description: 'Tools like pruners and trimmers to assist with landscaping and maintaining garden health efficiently.' },
  { id: 4, name: 'Dairy Products', category: 'Dairy-Products', image: ProductDairy, description: 'Dairy processing tools such as milk churners and separators to aid in milk production and processing on farms.' },
  { id: 5, name: 'Tractor Equipmets', category: 'Tractor-Equipmets', image: ProductTractor, description: 'Tractor attachments for plowing, seeding, and other essential farming tasks to enhance tractor versatility.' },
  { id: 6, name: 'Weedcutter Equipments', category: 'Weedcutter-Equipments', image: ProductWeed, description: 'Machines designed to efficiently cut and control weeds, helping improve crop yield by reducing competition.' },
  { id: 12, name: 'Weedcutter Equipments', category: 'Weedcutter-Equipments', image: ProductWeed1, description: 'Machines designed to efficiently cut and control weeds, helping improve crop yield by reducing competition.' },
  { id: 7, name: 'Honey Equipments', category: 'Honey-Equipments', image: ProductHoney, description: 'Beekeeping tools like honey extractors and bee smokers, making honey harvesting safer and more productive.' },
  { id: 8, name: 'Areca Equipments', category: 'Areca-Equipments', image: ProductAreca, description: 'Areca farming equipment streamlines the processing of areca nuts by automating tasks like dehusking, polishing, and separating bunches. These machines improve efficiency, reduce labor, and enable sustainable by-products like areca leaf plates.' },
  { id: 13, name: 'Areca Equipments', category: 'Areca-Equipments', image: ProductAreca1, description: 'ಅಡಿಕೆ ಕೃಷಿ ಉಪಕರಣಗಳು ಅಡಿಕೆ ಕೊಯ್ಯುವಿಕೆ, ಹೊಳೆಯುವಿಕೆ ಮತ್ತು ಗುಚ್ಚ ವಿಂಗಡಣೆ ಕಾರ್ಯಗಳನ್ನು ಸುಗಮಗೊಳಿಸುತ್ತವೆ. ಈ ಯಂತ್ರಗಳು ಶ್ರಮವನ್ನು ಕಡಿಮೆ ಮಾಡುತ್ತವೆ, ಪರಿಣಾಮಕಾರಿತ್ವವನ್ನು ಹೆಚ್ಚಿಸುತ್ತವೆ ಮತ್ತು ಅಡಿಕೆ ಎಲೆಗಳಿಂದ ಪರಿಸರ ಸ್ನೇಹಿ ತಟ್ಟೆಗಳನ್ನು ತಯಾರಿಸಲು ಸಹಕಾರಿ' },
  { id: 9, name: 'Wheel Borrows', category: 'Wheel-Borrows', image: ProductWheel, description: 'Handy transport tools for moving soil, plants, and materials around the farm with ease and efficiency.' },
  { id: 10, name: 'Sprayers', category: 'Sprayers', image: ProductSprayer, description: 'Equipment for applying pesticides and fertilizers uniformly to protect and nourish crops.' },
  { id: 11, name: 'Climbing Equipments', category: 'Other-Equipments', image: Productother1, description: 'These are a variety of aluminum ladders and climbing tools designed for different agricultural and household needs. The aluminum self-support stool ladder and wall ladder (bipole ladder) offer stability and safety for tasks requiring height. Other tools like the coconut climber, 2-rod and 3-rod ladders, and the areca tree bike provide efficient solutions for tree climbing and agricultural maintenance, ensuring easy access to high branches or areas.' },
  { id: 14, name: 'Sprayers', category: 'Sprayers', image: ProductSorayer1, description: 'Equipment for applying pesticides and fertilizers uniformly to protect and nourish crops.' },
  { id: 15, name: 'Chainsaw', category: 'Other-Equipments', image: Productother2, description: 'The chainsaw is a versatile tool designed for efficient cutting, pruning, and tree maintenance. It helps with tasks such as felling trees, cutting logs, and clearing overgrown vegetation. Ideal for both agricultural and forestry work, it ensures fast and precise cutting for improved productivity. Its lightweight design and powerful engine make it easy to use for extended periods, enhancing overall efficiency in outdoor tasks.' },
  { id: 16, name: 'Power Tools', category: 'Other-Equipments', image: Productother3, description: 'The tools listed include essential agricultural equipment designed to improve farming efficiency and protect crops. Items like the Easy Cut, Kasei Leaf Blower, and Mist Blower assist with maintenance and crop care, while the Shadenet, Weed Mat, and Rain Cover provide protection against environmental factors. Additionally, tools like the Auger, Nursery Tray, and Stubble Mower (KK-STB-050) help with planting, seedling propagation, and land preparation.' },
  { id: 17, name: 'Weedcutter Equipments', category: 'Weedcutter-Equipments', image: Productweed3, description: 'Machines designed to efficiently cut and control weeds, helping improve crop yield by reducing competition.' },
  { id: 18, name: 'Other Equipments', category: 'Other-Equipments', image: Productothers, description: 'The tools listed include essential agricultural equipment designed to improve farming efficiency and protect crops. Items like the Easy Cut, Kasei Leaf Blower, and Mist Blower assist with maintenance and crop care, while the Shadenet, Weed Mat, and Rain Cover provide protection against environmental factors. Additionally, tools like the Auger, Nursery Tray, and Stubble Mower (KK-STB-050) help with planting, seedling propagation, and land preparation.' },

];

const collections = [
  { id: 'Irrigation', name: 'Irrigation', imgSrc: Irregationimage },
  { id: 'Vacuum-Clearner', name: 'Vacuum Clearner', imgSrc: VACCUMCLEANER },
  { id: 'Garden-Equipmets', name: 'Garden Equipmets', imgSrc: GARDENEQUIPMENTS },
  { id: 'Dairy-Products', name: 'Dairy Products', imgSrc: DAIRYPRODUCTS },
  { id: 'Tractor-Equipmets', name: 'Tractor Equipmets', imgSrc: TRACTOREQUIPMENTS },
  { id: 'Weedcutter-Equipments', name: 'Weedcutter Equipments', imgSrc: WEEDCUTTERATTACHMENTS },
  { id: 'Honey-Equipments', name: 'Honey Equipments', imgSrc: HONEYEQUIPMENTS },
  { id: 'Areca-Equipments', name: 'Areca Equipments', imgSrc: ARECAEQUIPMENTS },
  { id: 'Wheel-Borrows', name: 'Wheel Borrows', imgSrc: WHEELBORROW },
  { id: 'Sprayers', name: 'Sprayers', imgSrc: SPRAYER },
  { id: 'Other-Equipments', name: 'Other Equipments', imgSrc: OTHEREQUIPMENTS }
];


const ProductPage: React.FC = () => {
  const navigate = useNavigate();
  const { category } = useParams<{ category?: string }>();
  const filteredProducts = category ? products.filter(p => p.category === category) : products;

  const handleCollectionClick = (collectionId: string) => {
    navigate(`/products/${collectionId}`);
  };

  return (
    <div className="product-page">
      <h2>{category ? `Products in ${category}` : 'All Categories'}</h2>
      {category ? (<div className="products">
        {filteredProducts.map((product) => (
          <div key={product.id} className="product-card">
            <div className="product-details">
              <h3>{product.name}</h3>
              <p>{product.description}</p>
            </div>
            <img src={product.image} alt={product.name} className="product-image" />
          </div>
        ))}
      </div>):(<div className="categories-page">
        <div className="categories-container">
          <div className="categories">
            {collections.map((collection) => (
              <CollectionCard
                key={collection.id}
                id={collection.id}
                name={collection.name}
                imgSrc={collection.imgSrc}
                onClick={handleCollectionClick}
              />
            ))}
          </div>
        </div>
      </div>)}
    </div>
  );
};

export default ProductPage;
