import React from 'react';
import './Home.scss';
import CounterSection from '../SmallComponents/CounterCard';
import Slider from "../CustomSlider/Slider";
import Image1 from "../../assets/images/installation-setup.jpg";
import Image2 from "../../assets/images/product-support.jpg";
import Image3 from "../../assets/images/collection-garden-equipment-meadow.jpg";
import Image4 from "../../assets/images/consultancy.jpg";
import CollectionPage from './collection/CollectionPage';
import ServiceList1 from '../../assets/images/service-list-1.jpg';
import ServiceList2 from '../../assets/images/service-list-2.jpg';
import BlogContainer from './blog/BlogContainer';
import BrandMarquee from './BrandMarquee/BrandMarquee';
import FeaturesSection from './FeaturesSection/FeaturesSection';


const Home: React.FC = () => {
  const slides = [
    {
      image: Image2,
      title: "Easy Life Enterprises",
      desc: "Your Trusted Partner in Agricultural Equipment Sales & Services.",
      link: "#",  // Optional link for button
    },
    {
      image: Image1,
      title: "The farmer is the only man in our economy who buys everything at retail, sells everything at wholesale, and pays the freight both ways.",
      desc: "",
    },
    {
      image: Image3,
      title: "ಎಷ್ಟೇ ಎತ್ತರಕ್ಕೆ ಏರಿದರೂ ಅನ್ನ ತಿನ್ನಲು ತಲೆ ಬಾಗಲೇಬೇಕು",
      desc: "",
    },
    {
      image: Image4,
      title: "ಅನ್ನ ಕೊಡುವ ರೈತ ಚೆನ್ನಾಗಿದ್ದರೆ ದೇಶ ಚೆನ್ನಾಗಿದ್ದಹಾಗೆ",
      desc: "",
    },
    {
      image: Image3,
      title: "ಅನ್ನನಾ ದ್ಯಾವ್ರು ಅಂತಾರೆ.. ಅಂತ ದ್ಯಾವ್ರನ್ನೇ ಸೃಷ್ಠಿ ಮಾಡೋದು ರೈತ ಮಾತ್ರನೇ",
      desc: "",
    },
    {
      image: Image4,
      title: "ಸಕಲರಿಗೂ ಸಮೃದ್ಧಿ ಸಾವಯವ ಮಳೆಬೇಸಾಯದಲ್ಲಿ",
      desc: "",
    },
  ];
  return (
    <div className="home-container">
      <section>
      <Slider slides={slides} autoPlay={true} intervalTime={3000} />
      </section>
      {/* <header className="hero-section">
        <h1>Easy Life Enterprises</h1>
        <p>Your Trusted Partner in Agricultural Equipment Sales & Services</p>
      </header> */}
      <section>
        <FeaturesSection/>
      </section>
      <section>
      <CollectionPage />
      </section>
      <section>
        <CounterSection/>
      </section>

      <section className="brands-section">
      <h2>Brands We Deal In</h2>
      <BrandMarquee />
      </section>

      <section className="mission-section">
        <h2>Our Mission</h2>
        <p>
          At Easy Life Enterprises, we aim to simplify your farming operations. Our mission is to provide reliable, innovative, and efficient agricultural equipment that enhances productivity while ensuring sustainable practices. We believe that every farmer deserves access to the best tools available, and we’re here to make that happen.
        </p>
      </section>
      

      <section className="services-section">
        <h2>What We Offer</h2>
        {/* <p>Explore our extensive range of high-quality agricultural machinery designed to meet the diverse needs of modern farming.</p> */}
        <p>ಕೃಷಿ ಸಾಧನ ಮಾರಾಟ
        ಆಧುನಿಕ ಕೃಷಿಯ ವೈವಿಧ್ಯಮಯ ಅಗತ್ಯಗಳಿಗೆ ತಕ್ಕಂತಾದ ಉತ್ತಮ ಗುಣಮಟ್ಟದ ಕೃಷಿ ಯಂತ್ರೋಪಕರಣಗಳ ವ್ಯಾಪಕ ಶ್ರೇಣಿಯನ್ನು ಅನ್ವೇಷಿಸಿ. ಟ್ರ್ಯಾಕ್ಟರ್‌ಗಳು ಮತ್ತು ಪ್ಲೋಗಳಲ್ಲಿಂದ ಆರಂಭಿಸಿ, ನೀರಾವರಿ ವ್ಯವಸ್ಥೆಗಳ ಮತ್ತು ಬೀಜ ಬೆಳೆಸುವ ಸಾಧನಗಳಿಗೆ, ನಿಮ್ಮ ಉತ್ಪಾದಕತೆಯನ್ನು ಹೆಚ್ಚಿಸಲು ಮತ್ತು ನಿಮ್ಮ ಕಾರ್ಯಗಳನ್ನು ಸುಲಭಗೊಳಿಸಲು ಅಗತ್ಯವಿರುವ ಎಲ್ಲವೂ ಇಲ್ಲಿ ಇದೆ</p>
        <div className="services-list">
          <div className="service-list-container">
          <div className="sercive-image">
            <img src={ServiceList1} alt="" />
          </div>
          <div className="service-card">
            <h3>Agricultural Equipment Sales & Services</h3>
            <p>
            Explore our extensive range of high-quality agricultural machinery designed to meet the diverse needs of modern farming. From tractors and plows to irrigation systems and seeders, we have everything you need to boost your productivity and streamline your operations.
            We also help retailers establish a good customer experience by providing  agricultural equipment spares /parts at a wholesale price  all over india .We have also joined hands with top Ecommerce sites such as Indiamart ,Amazon & Flipkart .
            </p>
          </div>
          </div>
          <div className="service-list-container-2">
          <div className="service-card">
            <h3>Retailers & Wholesale Parts</h3>
            <p>
            Comprehensive Services
In addition to sales, Easy Life Enterprises offers a full suite of services to keep your equipment running smoothly. Our expert technicians provide maintenance, repairs, and consultations to ensure your machinery operates at peak performance.
Customized Solutions
Every farm is unique, and we understand that one size does not fit all. Our team works closely with you to develop customized solutions that address your specific challenges and goals. Whether you’re a small family farm or a large agricultural enterprise, we’ve got you covered.
            </p>
          </div>
          <div className="service-image">
            <img src={ServiceList2} alt="" />
          </div>
          </div>
        </div>
      </section>
      <section>
        <div className="BlogContainer">
          <BlogContainer/>
        </div>
      </section>
    </div>
  );
};

export default Home;
