import React, { useEffect, useState } from "react";
import { FaArrowCircleRight, FaCaretLeft, FaCaretRight } from "react-icons/fa";
import "./Slider.scss";

// Define the slide type for better type safety
interface Slide {
  image: string;
  title: string;
  desc: string;
  link?: string;  // Optional link for the button
}

interface SliderProps {
  slides: Slide[];
  autoPlay?: boolean;  // Whether to auto-slide or not
  intervalTime?: number;  // Time interval for auto sliding (in ms)
}

const Slider: React.FC<SliderProps> = ({ slides, autoPlay = true, intervalTime = 5000 }) => {
  const [slideIndex, setSlideIndex] = useState(1);

  useEffect(() => {
    if (autoPlay) {
      const interval = setInterval(() => {
        setSlideIndex((prevIndex) => (prevIndex === slides.length ? 1 : prevIndex + 1));
      }, intervalTime);
      return () => clearInterval(interval);
    }
  }, [slides.length, autoPlay, intervalTime]);

  const showSlides = (n: number) => {
    setSlideIndex(n > slides.length ? 1 : n < 1 ? slides.length : n);
  };

  return (
    <div className="slider-container">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slide ${slideIndex === index + 1 ? "active" : "fade"}`}
          style={{ display: slideIndex === index + 1 ? "block" : "none" }}
        >
          <img className="slide-image" src={slide.image} alt={slide.title} />
          <div className="slide-content">
            <h3 className="slide-title">{slide.title}</h3>
            <p className="slide-desc">{slide.desc}</p>
            {slide.link && (
              <a href="/contact" className="slide-btn">
                Contact Us <FaArrowCircleRight />
              </a>
            )}
          </div>
          <div className="slide-number-container">
            <p className="slider-number">{`0${index + 1}`}</p>
            <hr />
            <p className="slider-number">{`0${slides.length}`}</p>
          </div>
        </div>
      ))}

      <div className="slider-nav">
        <button className="slider-nav-btn" onClick={() => showSlides(slideIndex - 1)}>
          <FaCaretLeft />
        </button>
        <button className="slider-nav-btn" onClick={() => showSlides(slideIndex + 1)}>
          <FaCaretRight />
        </button>
      </div>

      <div className="dot-container">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${slideIndex === index + 1 ? "active" : ""}`}
            onClick={() => showSlides(index + 1)}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
