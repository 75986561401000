import React from 'react';
import './CollectionCard.scss';

interface CollectionCardProps {
  id: string;
  name: string;
  imgSrc: string;
  onClick: (id: string) => void;
}

const CollectionCard: React.FC<CollectionCardProps> = ({ id, name, imgSrc, onClick }) => {
  return (
    <div className="collection-card" onClick={() => onClick(id)}>
      <img src={imgSrc} alt={name} />
      <h3>{name}</h3>
    </div>
  );
};

export default CollectionCard;
