import React, { useState, useEffect } from 'react';
import './Header.scss';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook-icon.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtube-icon.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/icons/whatsapp-icon.svg';
import Logo from "../../assets/images/EASYLIFE LOGO.png";

const Header: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showSocial, setShowSocial] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false); // Track if the menu is open on mobile

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
      if (window.scrollY > lastScrollY) {
        setShowSocial(false);
      } else {
        setShowSocial(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {/* Social media and phone number section */}
      {showSocial && (
        <div className="social-container">
          <div className="contact-info">
            <a href="tel:+91 9901876682" className="phone">Call us: +91 990 187 6682</a>
          </div>
          <div className="social-icons">
            <a href="https://wa.me/message/MYSUG2OU4EFBN1" target="_blank" rel="noopener noreferrer">
              <WhatsappIcon className="social-icon" />
            </a>
            <a href="https://www.instagram.com/easylifeenterprises/profilecard/?igsh=MTh1MnE1aG95N2E5cA==" target="_blank" rel="noopener noreferrer">
              <InstagramIcon className="social-icon" />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FacebookIcon className="social-icon" />
            </a>
            <a href="https://youtube.com/@easylifeenterprises2536?si=0ebLPqbNxjVMmO_q" target="_blank" rel="noopener noreferrer">
              <YoutubeIcon className="social-icon" />
            </a>
          </div>
        </div>
      )}

      {/* Header */}
      <header className={`header-container ${scrolled ? 'scrolled' : ''}`}>
        <div className="logo">
          <img src={Logo} alt='Easylife logo' ></img>
          {/* <h1>Easy Life Enterprises</h1> */}
        </div>

        {/* Hamburger Menu for Mobile */}
        <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${menuOpen ? 'open' : ''}`}></div>
        </div>

        {/* Navigation Bar */}
        <nav className={`navbar ${menuOpen ? 'open' : ''}`}>
          <a href="/">Home</a>
          <a href="/about">About</a>
          <a href="/branches">Branches</a>
          <a href="/products">Products</a>
          <a href="/services">Services</a>
          <a href="/contact">ContactUs</a>
        </nav>
      </header>
    </div>
  );
};

export default Header;
