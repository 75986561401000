import React from 'react';
import './FeaturesSection.scss';
import Freture1 from '../../../assets/icons/truck.png';
import Freture2 from '../../../assets/icons/geniun.jpg';
import Freture3 from '../../../assets/icons/verified.jpg';
import Freture4 from '../../../assets/icons/refund.jpg';


interface Feature {
  icon: string;
  title: string;
}

const features: Feature[] = [
  { icon: Freture1, title: 'Fast & Secured Delivery' },
  { icon: Freture2, title: 'Genuine and Authentic' },
  { icon: Freture3, title: '100% Safe & Secure Payment' },
  { icon: Freture4, title: 'Satisfied or Refunded' },
];

const FeaturesSection: React.FC = () => {
  return (
    <div className="features-section">
      {features.map((feature, index) => (
        <div key={index} className="feature-item">
          <img src={feature.icon} alt={feature.title} className="feature-icon" />
          <p className="feature-title">{feature.title}</p>
        </div>
      ))}
    </div>
  );
};

export default FeaturesSection;
