import React, { useState, useEffect, useRef } from 'react';
import './CounterCard.scss';
import CrownIcon from "../../assets/icons/crown.png";
import PeopleRating from "../../assets/icons/People rating.png";
import BarndIcon from "../../assets/icons/brand.png";
import SalesIcon from "../../assets/icons/sales.png";
import DistributionIcon from "../../assets/icons/disribution.png"

const CounterCard = ({ icon, number, label }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const top = counterRef.current.getBoundingClientRect().top;
      if (top < window.innerHeight && top > 0) {
        startCount();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const startCount = () => {
    let start = 0;
    const end = number;
    const duration = 2000;
    const increment = Math.ceil(end / (duration / 20));

    const timer = setInterval(() => {
      start += increment;
      if (start > end) {
        start = end;
        clearInterval(timer);
      }
      setCount(start);
    }, 20);
  };

  return (
    <div ref={counterRef} className="counter-card">
      <img src={icon} alt={label} className="icon" />
      <h2 className="number">{count}+</h2>
      <p className="label">{label}</p>
    </div>
  );
};

const CounterSection = () => {
  return (
    <div className="counter-section">
      <CounterCard icon={CrownIcon} number={5000} label="Products" />
      <CounterCard icon={PeopleRating} number={9000} label="Customers" />
      <CounterCard icon={BarndIcon} number={25} label="Brands" />
      <CounterCard icon={SalesIcon} number={20000} label="Sales" />
      <CounterCard icon={DistributionIcon} number={302} label="Distributors" />
    </div>
  );
};

export default CounterSection;
