import React from 'react';
import './About.scss';
import qualityImage from '../../assets/images/qaulity1.webp'; 
import customerImage from '../../assets/images/customer-saticfaction.webp';
import affordabilityImage from '../../assets/images/affordable.webp';

const About: React.FC = () => {
  return (
    <div className="about-container">
      <section className="about-header">
        <h1>About Easy Life Enterprises</h1>
        <p>Your trusted partner in agricultural equipment and services</p>
      </section>

      <section className="about-content">
        <h2 className="fade-in">Who We Are</h2>
        <p className="slide-up">
        Easy Life Enterprises is a trusted leader in agricultural equipment sales and services, with over 12 years of experience helping farmers and agricultural businesses succeed. With a network of 25+ branches across five districts, we offer an extensive range of over 1,000 products, from tractors and plows to advanced irrigation systems and seeders. Our commitment to quality and customer satisfaction has earned us the loyalty of 50,000+ happy customers nationwide.
        </p>
        <p className="slide-up">
We work with top manufacturers to ensure that every piece of equipment meets industry standards for reliability, innovation, and sustainability. Additionally, we've partnered with leading e-commerce platforms like IndiaMART, Amazon, and Flipkart, making it easy for our customers to access high-quality agricultural parts and spares at wholesale prices throughout India.
</p>
<p className="slide-up">
Our knowledgeable team is dedicated to providing expert support and tailored solutions to meet the unique needs of each farm. From sales and maintenance to personalized consultations, we strive to be a trusted partner in every aspect of agricultural operations.
        </p>
<p className='note'>*Easy Life Enterprises – Simplifying Farming, One Solution at a Time.*</p>
        <h2 className="fade-in">Our Values</h2>
        <div className="values-grid">
          <div className="value-card slide-in">
            <img src={qualityImage} alt="Quality" />
            <h3>Quality</h3>
            <p>We partner with leading manufacturers to deliver only the best equipment.</p>
          </div>
          <div className="value-card slide-in">
            <img src={customerImage} alt="Customer-Centric" />
            <h3>Customer-Centric</h3>
            <p>Your satisfaction is our priority. We build lasting relationships with our clients.</p>
          </div>
          <div className="value-card slide-in">
            <img src={affordabilityImage} alt="Affordability" />
            <h3>Affordability</h3>
            <p>We aim to provide excellent value while maintaining competitive prices.</p>
          </div>
        </div>

        <h2 className="fade-in">Join Our Community</h2>
        <p className="slide-up">
          Be part of the Easy Life Enterprises family. Whether you’re looking for new equipment or need reliable service, we’re here to help every step of the way.
        </p>
      </section>
    </div>
  );
};

export default About;
