import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Services from './components/Services/Services';
import Branches from './components/Branches/Branches';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'swiper/swiper-bundle.min.css';
import 'font-awesome/css/font-awesome.min.css';
import BranchDetails from './components/Branches/BranchDetails';
import ScrollToTopButton from './components/SmallComponents/ScrollToTopButton';
import ProductPage from './components/Products/Products';
// import 'bootstrap/dist/css/bootstrap.min.css';




function App() {
  const [loading, setLoading] = useState(true);
  // Simulate loading time on page load or refresh
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000); // Adjust timeout as needed
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <Router>
      <div className="app">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/branches" element={<Branches/>} />
            <Route path="/branches/:district" element={<BranchDetails />} />
            <Route path="/products" element={<ProductPage />} />
            <Route path="/products/:category" element={<ProductPage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer />
        <ScrollToTopButton />
      </div>
    </Router>
  );
};

export default App;
