import React from 'react';
import "./Services.scss";
import Slider from '../CustomSlider/Slider';
import ServiceImage1 from '../../assets/images/Products/easylife product catalogue edited new DDD final 1-15 page A (1)_page-0001.jpg'
import ServiceImage2 from '../../assets/images/Products/easylife product catalogue edited new DDD final 1-15 page A (1)_page-0002.jpg'
import ServiceImage3 from '../../assets/images/Products/easylife product catalogue edited new DDD final 1-15 page A (1)_page-0003.jpg'

const ServicesPage: React.FC = () => {
  return (
    <div className="services-page">
      <h2>Our Services</h2>
      <div className="service-images">
        <div className="service-image">
          <img src={ServiceImage1} alt="Service 1" />
        </div>
        <div className="service-image">
          <img src={ServiceImage2} alt="Service 2" />
        </div>
        <div className="service-image">
          <img src={ServiceImage3} alt="Service 3" />
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
