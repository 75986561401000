import React from 'react';
import './BrandMarquee.scss';
import Brand1 from "../../../assets/images/Brands/download.png";
import Brand2 from "../../../assets/images/Brands/honda.jpg";
import Brand3 from "../../../assets/images/Brands/husq.png";
import Brand4 from "../../../assets/images/Brands/jaguar.jpg";
import Brand5 from "../../../assets/images/Brands/kisankraft-kk-stb-050-inter-power-cultivator.jpg";
import Brand6 from "../../../assets/images/Brands/maki.jpg";
import Brand7 from "../../../assets/images/Brands/redla.png";
import Brand8 from "../../../assets/images/Brands/stihl.png";
import Brand9 from "../../../assets/images/Brands/sun.png";
import Brand10 from "../../../assets/images/Brands/ultima-og.webp";



// Array of brand logo URLs
const brandLogos = [
    Brand1,
    Brand2,
    Brand3,
    Brand4,
    Brand5,
    Brand6,
    Brand7,
    Brand8,
    Brand9,
    Brand10
];

const BrandMarquee: React.FC = () => {
  return (
    <div className="brand-marquee">
      <div className="marquee">
        {brandLogos.map((logo, index) => (
          <img key={index} src={logo} alt={`Brand ${index + 1}`} className="brand-logo" />
        ))}
        {brandLogos.map((logo, index) => (
          <img key={`duplicate-${index}`} src={logo} alt={`Brand ${index + 1}`} className="brand-logo" />
        ))}
      </div>
    </div>
  );
};

export default BrandMarquee;
