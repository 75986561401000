import React from 'react';
import './BlogContainer.scss';

const videos = [
  { id: 'kwRU0VU-plQ?si=dhWjPqXjODvYx2vh', title: 'World Top Quality Brush Cutter' },
  { id: 'Yipq26o_a48?si=FnS3H2oaE-BnQR_Y', title: 'ಕೃಷಿಕರಿಗೆ ಬೇಕಾದ ಎಲ್ಲಾ ರೀತಿಯ ಯಂತ್ರೋಪಕರಣಗಳು ಒಂದೇ ಸೂರಿನಡಿ ಲಭ್ಯ' },
  { id: 'lM8TS4jmz8I?si=4TEwlb8tnnL0iWfp', title: 'ಮನೆಯ ತೋಟ ಮತ್ತು ಮಧ್ಯಮ ಉಳುಮೆಯ ಕೆಲಸವನ್ನು ಸುಲಭವಾಗಿಸುವ ಮೈಜೊ ಮಹಾವೀರ್' },
  { id: 'k7aCvBf3RGg?si=_Pn0Q_CH8PBtDD3B', title: 'MULTIPURPOSE WASHER' },
  { id: 'Eiy6QrEg_7g?si=aIN-t58wOiY6pl3F', title: 'ಬೋರ್ಡೋ ಮಿಶ್ರಣ ತಯಾರಿಸುವುದು ಹೇಗೆ ???? ಅಡಿಕೆ ಕೊಳೆ ರೋಗಕ್ಕೆ ಇಲ್ಲಿದೆ ಪರಿಹಾರ' },
  { id: 'pvnoG28YCsI?si=Idh9YaqI-5yo9srh', title: '#ಹೊಂಡತೆಗೆಯುವಯಂತ್ರ #EARTHAUGER #ಗುಂಡಿತೋಡುವಯಂತ್ರ #EASYLIFEENTERPRISES ' },
];

const BlogContainer = () => {
  return (
    <div className="blog-container">
      <h2 className="blog-title">Latest Videos</h2>
      <div className="video-grid">
        {videos.map((video) => (
          <div key={video.id} className="video-card">
            <iframe
              width="100%"
              height="auto"
              src={`https://www.youtube.com/embed/${video.id}`}
              title={video.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <p>{video.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogContainer;
