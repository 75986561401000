import React from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import branchData from '../../data/branches.json';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './BranchDetails.scss';
import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

interface Branch {
    name: string;
    images: string | string[];
    phone: string;
    email: string;
    address: string;
    latitude: number;
    longitude: number;
}

interface DistrictData {
    district: string;
    branches: Branch[];
}

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const BranchDetails: React.FC = () => {
    const { district } = useParams<{ district: string }>();
    const districtData = branchData.find((d) => d.district === district) as DistrictData | undefined;

    if (!districtData) return <div>No branches found for this district.</div>;

    const getDirections = (latitude: number, longitude: number) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude: userLat, longitude: userLng } = position.coords;
                const url = `https://www.google.com/maps/dir/?api=1&origin=${userLat},${userLng}&destination=${latitude},${longitude}`;
                window.open(url, '_blank', 'noopener,noreferrer');
            }, () => {
                const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
                window.open(url, '_blank', 'noopener,noreferrer');
            });
        } else {
            const url = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: true,
    };

    return (
        <div className="branch-details">
            <h1>{district} Branches</h1>
            <div className="branch-grid">
                {districtData.branches.map((branch, index) => (
                    <div key={index} className="branch-card">
                        {Array.isArray(branch.images) && branch.images.length > 0 && (
                            <Slider {...sliderSettings}>
                                {branch.images.map((image, i) => (
                                    <div key={i} className="carousel-item">
                                        <img src={image} className="d-block w-100" alt={`${branch.name} ${i + 1}`} />
                                    </div>
                                ))}
                            </Slider>
                        )}
                        <h2>{branch.name}</h2>
                        <p>Phone: <a href={`tel:${branch.phone}`}>{branch.phone}</a></p>
                        <p>Email: <a href={`mailto:${branch.email}`}>{branch.email}</a></p>
                        <p>Address: {branch.address}</p>
                        {branch.latitude && branch.longitude && (
                            <>
                                <button
                                    onClick={() => getDirections(branch.latitude, branch.longitude)}
                                    className="get-directions-btn"
                                    aria-label={`Get directions to ${branch.name}`}
                                >
                                    Get Directions
                                </button>
                                <div className="map-container">
                                    <MapContainer
                                        center={[branch.latitude, branch.longitude]}
                                        zoom={13}
                                        style={{ height: '200px', width: '100%' }}
                                    >
                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                        <Marker position={[branch.latitude, branch.longitude]}>
                                            <Popup>{branch.name}</Popup>
                                        </Marker>
                                    </MapContainer>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BranchDetails;
