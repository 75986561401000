import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CollectionCard from './CollectionCard';
import './Collection.scss';
import Irregationimage from '../../../assets/images/collections/irregation.jpg';
import VACCUMCLEANER from '../../../assets/images/collections/Vaccum.jpg';
import DAIRYPRODUCTS from '../../../assets/images/collections/dairy.jpg';
import GARDENEQUIPMENTS from '../../../assets/images/collections/gradaning.jpg';
import HONEYEQUIPMENTS from '../../../assets/images/collections/honney.jpg';
import SPRAYER from '../../../assets/images/collections/spares.jpg';
import TRACTOREQUIPMENTS from '../../../assets/images/collections/tractor equpment.jpg';
import WHEELBORROW from '../../../assets/images/collections/wheel.jpg';
import ARECAEQUIPMENTS from '../../../assets/images/collections/areca.jpg';
import WEEDCUTTERATTACHMENTS from '../../../assets/images/collections/weed cutter.jpg';
import OTHEREQUIPMENTS from '../../../assets/images/collections/other.jpg';

const collections = [
  { id: 'Irrigation', name: 'Irrigation', imgSrc: Irregationimage },
  { id: 'Vacuum-Clearner', name: 'Vacuum Clearner', imgSrc: VACCUMCLEANER },
  { id: 'Garden-Equipmets', name: 'Garden Equipmets', imgSrc: GARDENEQUIPMENTS },
  { id: 'Dairy-Products', name: 'Dairy Products', imgSrc: DAIRYPRODUCTS },
  { id: 'Tractor-Equipmets', name: 'Tractor Equipmets', imgSrc: TRACTOREQUIPMENTS },
  { id: 'Weedcutter-Equipments', name: 'Weedcutter Equipments', imgSrc: WEEDCUTTERATTACHMENTS },
  { id: 'Honey-Equipments', name: 'Honey Equipments', imgSrc: HONEYEQUIPMENTS },
  { id: 'Areca-Equipments', name: 'Areca Equipments', imgSrc: ARECAEQUIPMENTS },
  { id: 'Wheel-Borrows', name: 'Wheel Borrows', imgSrc: WHEELBORROW },
  { id: 'Sprayers', name: 'Sprayers', imgSrc: SPRAYER },
  { id: 'Other-Equipments', name: 'Other Equipments', imgSrc: OTHEREQUIPMENTS }
];

const CollectionPage: React.FC = () => {
    const navigate = useNavigate();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
  
    const handleCollectionClick = (collectionId: string) => {
      navigate(`/products/${collectionId}`);
    };
  
    const handleViewAllClick = () => {
      navigate('/products');
    };
  
    const scroll = (direction: 'left' | 'right') => {
      if (scrollContainerRef.current) {
        const scrollAmount = 300; // Adjust the scroll amount as needed
        scrollContainerRef.current.scrollBy({
          left: direction === 'left' ? -scrollAmount : scrollAmount,
          behavior: 'smooth',
        });
      }
    };
  
    return (
      <div className="collection-page">
        <div className="header-icons">
          {/* Header with icons as shown */}
        </div>
        <div className='collection-header'><h2>Our Collections</h2><button className="view-all" onClick={handleViewAllClick}>View All</button></div>
        <div className="collections-container">
          <button className="scroll-btn prev-btn" onClick={() => scroll('left')}>❮</button>
          <div className="collections" ref={scrollContainerRef}>
            {collections.map((collection) => (
              <CollectionCard
                key={collection.id}
                id={collection.id}
                name={collection.name}
                imgSrc={collection.imgSrc}
                onClick={handleCollectionClick}
              />
            ))}
          </div>
          <button className="scroll-btn next-btn" onClick={() => scroll('right')}>❯</button>
        </div>
      </div>
    );
  };

export default CollectionPage;
