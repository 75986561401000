import React from 'react';
import { Link } from 'react-router-dom';
import branchData from '../../data/branches.json';
import './DistrictCard.scss';

interface DistrictCardProps {
    district: string;
}

const DistrictCard: React.FC<DistrictCardProps> = ({ district }) => {

    const branchCount = branchData.find(d => d.district === district)?.branches.length || 0;

    return (
        <Link to={`/branches/${district}`} className="district-card">
            <div className="card-content">
                <h2>{district}</h2>
                <p>{branchCount} {branchCount === 1 ? 'Branch' : 'Branches'}</p>
                <div className="card-footer">
                    <span className="view-more">View Details</span>
                </div>
            </div>
        </Link>
    );
};

export default DistrictCard;