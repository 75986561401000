import React from 'react';
import DistrictCard from './DistrictCard';
import branchData from '../../data/branches.json';
import './Branches.scss';

const Branches: React.FC = () => {
    const districts = [...new Set(branchData.map((branch) => branch.district))];
    

    return (
        <div className="branches-page">
            <h1>Our Branches</h1>
            <div className="district-grid">
                {districts.map((district) => (
                    <DistrictCard key={district} district={district} />
                ))}
            </div>
        </div>
    );
};

export default Branches;

